import { Fancybox, LikeButton, StatusLabel } from "@/components";
import { isAvailable, price as priceFormatter } from "@/services";
import { DetailedPlot, Status } from "@/types";
import { faArrowLeft } from "@awesome.me/kit-b9851c3d09/icons/classic/regular";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { NavLink } from "react-router-dom";

type HeaderProps = {
  plot: DetailedPlot;
  status: Status;
};

export default function Header({ plot, status }: Readonly<HeaderProps>) {
  const { name, project_type, price } = plot;
  const attachments = plot.attachments.slice(0, 5);

  return (
    <>
      <div className="col-span-full xl:col-start-2 xl:col-end-24">
        <NavLink
          to="../.."
          relative="path"
          className="flex items-center gap-x-2 hover:underline small"
        >
          <span className="text-lg">
            <FontAwesomeIcon icon={faArrowLeft} className="text-primary" />
          </span>
          <span>Terug</span>
        </NavLink>
      </div>

      <div className="flex flex-col mt-4 col-span-full gap-y-4 xl:space-between lg:flex-row xl:col-start-2 xl:col-end-24">
        <div className="flex flex-col">
          <h1 className="h1">Bwnr. {name}</h1>

          <span className="flex gap-2 !font-normal h3">
            {isAvailable(status)
              ? priceFormatter(parseInt(price), project_type)
              : "Onbeschikbaar"}
          </span>
        </div>

        <div className="relative flex flex-row-reverse items-center justify-between w-full gap-12 text-base lg:w-auto lg:ml-auto lg:mr-0 lg:flex-row">
          <LikeButton
            plot={plot}
            className="!relative !w-auto !h-auto !mx-0 lg:!m-auto bg-transparent text-black"
            withBackground={false}
          >
            <span className="ml-6 text-base text-black shrink-0">
              Als favoriet opslaan
            </span>
          </LikeButton>

          <StatusLabel status={status} className="!text-base !gap-x-3" />
        </div>
      </div>

      {attachments.length > 0 && (
        <Fancybox className="w-[calc(100%+2rem] -mx-4 mt-4 col-span-full lg:mx-0 lg:mt-16">
          <div
            className="flex flex-nowrwap max-w-[100dvw] grid-rows-plot-detail-header gap-3 overflow-auto whitespace-nowrap lg:grid lg:max-w-none"
            style={
              attachments.length === 1
                ? { gridTemplateAreas: '"a" "a"' }
                : attachments.length === 2
                ? { gridTemplateAreas: '"a b" "a b"' }
                : attachments.length === 3
                ? { gridTemplateAreas: '"a a b b" "a a c c"' }
                : attachments.length === 4
                ? { gridTemplateAreas: '"a a b b" "a a c d"' }
                : attachments.length === 5
                ? { gridTemplateAreas: '"a a b c" "a a d e"' }
                : undefined
            }
          >
            {attachments.map(({ id, original, srcset }, index) => (
              <button
                key={id}
                data-fancybox="gallery"
                className="max-w-[90dvw] lg:max-w-full shrink-0"
                data-src={original}
                data-srcset={srcset}
                data-sizes="200dvw"
                style={{
                  gridArea:
                    (index === 0 && "a") ||
                    (index === 1 && "b") ||
                    (index === 2 && "c") ||
                    (index === 3 && "d") ||
                    (index === 4 && "e") ||
                    "",
                }}
              >
                <img
                  src={original}
                  srcSet={srcset}
                  // The first image is shown larger than the rest
                  sizes={
                    index === 1
                      ? "(min-width: 1376px) 1376px, 90dvw"
                      : "(min-width: 1376px) 1376px, 90dvw"
                  }
                  className="object-cover object-center w-full h-full"
                  loading={index === 0 ? "eager" : "lazy"}
                  fetchPriority={"high"}
                  width={index === 0 ? 920 : 920}
                  height={index === 0 ? 920 : 460}
                  alt=""
                />
              </button>
            ))}
          </div>
        </Fancybox>
      )}
    </>
  );
}
