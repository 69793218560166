import { sortAlphabetically } from "@/services";
import clsx from "clsx";
import LayerNavLink from "./LayerNavLink";
import { Layer } from "@/types";

type LayerNavProps = {
  layers: Layer[];
  activeLayer: Layer;
  handleLayerNavClick: (layer: Layer) => void;
  className?: string;
};

export default function LayerNav({
  layers,
  activeLayer,
  handleLayerNavClick,
  className,
}: LayerNavProps) {
  return (
    <nav className={clsx(className)}>
      <div className="flex justify-start border border-black/10 shadow-layerNavBar max-w-[100dvw] lg:rounded-md lg:max-w-fit">
        <div className="flex items-center pl-6 pr-4 font-bold bg-white text-primary shrink-0 lg:rounded-l-md">
          <span className="text-sm lg:body">Woonlaag</span>
        </div>

        <ul className="flex items-center flex-grow py-2 pl-4 overflow-scroll border-l border-l-black/10 gap-x-1 bg-white/65 backdrop-blur-xl flex-nowrap lg:rounded-r-md lg:p-2">
          {layers
            .toSorted((a, b) => sortAlphabetically(a.slug, b.slug))
            .map((layer) => (
              <LayerNavLink
                key={layer.id}
                layer={layer}
                active={activeLayer.id.toString() === layer.id.toString()}
                onClick={handleLayerNavClick}
              />
            ))}
        </ul>
      </div>
    </nav>
  );
}
