import { buttonClasses, buttonIconClasses } from "@/services";
import { IconProp } from "@fortawesome/fontawesome-svg-core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import clsx from "clsx";
import React from "react";
import { To, useHref, useLinkClickHandler } from "react-router-dom";

type LinkOptions = {
  to: To;
  state?: any;
  onClick?: (event: React.MouseEvent<HTMLAnchorElement>) => void;
  children?: React.ReactNode;
  icon?: IconProp;
  target?: React.HTMLAttributeAnchorTarget;
  replace?: boolean;
  className?: string;
  variant?: "primary" | "secondary" | "ghost";
};

export default function Link({
  to,
  state,
  onClick,
  children,
  icon,
  target = "_self",
  replace = false,
  className = "",
  variant = "primary",
  ...rest
}: Readonly<LinkOptions>) {
  const hasIcon = !!icon;

  let href = useHref(to);
  let handleClick = useLinkClickHandler(to, {
    replace,
    state,
    target,
  });

  return (
    <a
      className={clsx(className, variant && buttonClasses(variant))}
      href={href}
      onClick={(event) => {
        onClick?.(event);
        if (to && !event.defaultPrevented) {
          handleClick(event);
        }
      }}
      {...rest}
    >
      {hasIcon && (
        <FontAwesomeIcon
          icon={icon}
          className={clsx(variant && buttonIconClasses(variant))}
        />
      )}
      {children}
    </a>
  );
}
