import ScrollToTop from "@/hooks/ScrollToTop";
import clsx from "clsx";
import { useRef } from "react";
import { Outlet, useNavigation } from "react-router-dom";

export default function Root({ children, className, ...rest }: any) {
  const navigation = useNavigation();
  const wrapperRef = useRef<HTMLDivElement>(null);

  return (
    <main {...rest} ref={wrapperRef} className={clsx(className, "antialiased")}>
      <ScrollToTop wrapper={wrapperRef} />

      {navigation.state === "loading" && (
        <div className="fixed inset-0 z-50 grid items-center justify-center grid-cols-1 grid-rows-1 place-items-center">
          <div className="absolute inset-0 z-10 bg-white/90 backdrop-blur-xl"></div>
          <span className="z-20">Laden...</span>
        </div>
      )}

      <Outlet />
    </main>
  );
}
