export const defaults = {
  hotspot: {
    pathOptions: {
      fill: true,
      fillOpacity: 0.2,
      fillColor: "#fff",
      stroke: true,
      opacity: 1,
      weight: 2,
      color: "#fff",
    },
    opacitySettings: {
      active: 0.9,
      matches: 0.25,
      default: 0.25,
    },
  },
};
