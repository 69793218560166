import { Button } from "@/components";
import { Layer } from "@/types";
import clsx from "clsx";

type LayerNavLinkProps = {
  active: boolean;
  layer: Layer;
  onClick: (layer: Layer) => void;
};

export default function LayerNavLink({
  active,
  layer,
  onClick,
}: Readonly<LayerNavLinkProps>) {
  return (
    <li key={layer.id}>
      <Button
        variant={active ? "secondary" : "ghost"}
        className={clsx(
          "inline-block px-3 py-2 rounded-lg text-sm transition-colors select-none",
          active && "hocus:!bg-primary hocus:!text-primary-contrast"
        )}
        onClick={() => onClick(layer)}
      >
        {layer.name}e
      </Button>
    </li>
  );
}
