import "@/components/Filter/Filter.css";
import { price as formatPrice, generateRange, price } from "@/services";
import { Plot } from "@/types";
import { Select } from "antd";
import clsx from "clsx";
import { useSearchParams } from "react-router-dom";

type PriceFilterProps = {
  values: Plot["price"][];
  showMinPrice: boolean;
  className?: string;
};

type Option = {
  value: string;
  label: string;
  disabled?: boolean;
};

const getPrices = (values: string[]): Option[] => {
  return generateRange(values, "both").map(
    (price): Option => ({
      value: price.toString(),
      label: formatPrice(price, null),
    })
  );
};

const disabledMaxPrices = (minPrice: number, prices: Option[]): Option[] => {
  return prices.reduce<Option[]>((accumulator, price) => {
    accumulator.push({
      ...price,
      disabled: parseInt(price.value) <= minPrice,
    });

    return accumulator;
  }, []);
};

export default function PriceFilter({
  values,
  showMinPrice,
  className,
}: Readonly<PriceFilterProps>) {
  const [searchParams, setSearchParams] = useSearchParams();
  const prices = getPrices(values);

  const defaultMin = searchParams.get("min_price")
    ? prices.find((price) => price.value === searchParams.get("min_price")) ||
      prices[0]
    : prices[0];

  const defaultMax = searchParams.get("max_price")
    ? prices.find((price) => price.value === searchParams.get("max_price")) ||
      prices[prices.length - 1]
    : prices[prices.length - 1];

  const maxPrices = disabledMaxPrices(parseInt(defaultMin.value), prices);
  const minPrices = prices.map((price, index) => {
    return {
      ...price,
      disabled: index === prices.length - 1,
    };
  });

  const handleChange = (
    selectedValue: string,
    slug: "min_price" | "max_price"
  ) => {
    if (selectedValue.toString() === "") {
      searchParams.delete(slug);
    } else {
      searchParams.set(slug, selectedValue.toString());
    }

    setSearchParams(searchParams);
  };

  const labelRender1 = () => {
    return defaultMin.value.toString() !== prices[0].value ? (
      <span>
        Min. prijs: {price(parseInt(defaultMin.value.toString()), null)}
      </span>
    ) : (
      "Min. prijs"
    );
  };

  const labelRender2 = () => {
    return defaultMax.value.toString() !== prices[prices.length - 1].value ? (
      <span>Max. prijs: {price(parseInt(defaultMax.value), null)}</span>
    ) : (
      "Max. prijs"
    );
  };

  return (
    <fieldset className="flex gap-1">
      {showMinPrice ? (
        <>
          <Select
            labelRender={labelRender1}
            defaultValue={"Minimale prijs"}
            showSearch={false}
            className={clsx(className, "border rounded")}
            options={minPrices}
            onChange={(selected) => handleChange(selected, "min_price")}
            getPopupContainer={(e) => e.closest("main")}
          />

          <Select
            labelRender={labelRender2}
            defaultValue={"Maximale prijs"}
            showSearch={false}
            options={maxPrices}
            className={clsx(className, "border rounded")}
            onChange={(selected) => handleChange(selected, "max_price")}
            getPopupContainer={(e) => e.closest("main")}
          />
        </>
      ) : (
        <Select
          labelRender={labelRender2}
          defaultValue={"Maximale prijs"}
          showSearch={false}
          options={maxPrices}
          className={clsx(className, "h-full")}
          onChange={(selected) => handleChange(selected, "max_price")}
          getPopupContainer={(e) => e.closest("main")}
        />
      )}
    </fieldset>
  );
}
