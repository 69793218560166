import {
  Button,
  Text,
  Radio,
  Radios,
  Checkbox,
  Checkboxes,
} from "@/components";
import { faPaperPlane } from "@awesome.me/kit-b9851c3d09/icons/classic/regular";
import { FormApi, useForm } from "@tanstack/react-form";
import { zodValidator } from "@tanstack/zod-form-adapter";
import clsx from "clsx";
import { useParams, useSearchParams } from "react-router-dom";
import { z } from "zod";
import "./Form/checkbox.css";
import "./Form/radio.css";
import { client } from "@/api/client";
import { Status, Plot } from "@/types";
import { useState } from "react";

type WishlistFormProps = {
  availableStatus: Status;
  enabled?: boolean;
  className?: string;
  favorites: Plot[] | null;
};

type BackendApiError = {
  response: {
    data: { errors: { [key: string]: string } };
  };
};

/**
 * Make sure there are always 5 wishlist items in the form.
 */
const padWishlistItems = (items: Plot[] | null): string[] => {
  if (!items) return Array(5).fill("");

  const ids = items.map((item) => item.id.toString());

  if (ids.length >= 5) return ids;

  return ids.concat(Array(5 - ids.length).fill(""));
};

export default function WishlistForm({
  availableStatus,
  className,
  favorites,
  enabled = false,
}: WishlistFormProps) {
  const [searchParams] = useSearchParams();
  const { projectSlug, phaseSlug } = useParams();
  const wishlistItems = padWishlistItems(favorites);
  const [submissionSuccessful, setSubmissionSuccessful] = useState(false);

  const defaultValues = {
    firstname: searchParams.get("voornaam") ?? "",
    lastname: searchParams.get("achternaam") ?? "",
    email: searchParams.get("email") ?? "",
    keyoccupation:
      searchParams.get("sleutelberoep") === "ja" ? "true" : "false",
    voorkeur_1: wishlistItems[0],
    voorkeur_2: wishlistItems[1],
    voorkeur_3: wishlistItems[2],
    voorkeur_4: wishlistItems[3],
    voorkeur_5: wishlistItems[4],
    privacy: false,
    url: window.location.href,
  };

  const resetForm = (
    formApi: FormApi<typeof defaultValues>,
    value: typeof defaultValues
  ) => {
    Object.keys(value).forEach((fieldKey) => {
      const key = fieldKey as keyof typeof defaultValues;

      formApi.setFieldValue(key, () => {
        if (key === "keyoccupation") return "false";
        if (key === "privacy") return "false";
        return "";
      });
    });
  };

  const addErrorsToFormFields = (
    formApi: FormApi<typeof defaultValues>,
    errors: BackendApiError["response"]["data"]["errors"]
  ) => {
    Object.entries(errors).forEach(([fieldKey, message]) => {
      const key = fieldKey as keyof typeof defaultValues;

      formApi.setFieldMeta(key, (prev) => ({
        ...prev,
        errorMap: {
          onSubmit: message,
        },
      }));
    });
  };

  const form = useForm({
    defaultValues: defaultValues,
    onSubmit: async ({ value, formApi }) => {
      client
        .post(`${projectSlug}/phases/${phaseSlug}/submission`, value, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .then((response) => {
          resetForm(formApi, value);
          setSubmissionSuccessful(true);
          return response;
        })
        .catch(function (error: BackendApiError) {
          if (!error.response?.data.errors) {
            console.log(error);
            return;
          }

          const errors = error.response.data.errors;
          addErrorsToFormFields(formApi, errors);
        });
    },
  });

  return (
    <form
      className={clsx(className)}
      encType="multipart/form-data"
      onSubmit={(e) => {
        e.preventDefault();
        e.stopPropagation();

        if (!enabled) return;
        form.handleSubmit();
      }}
      autoComplete="on"
    >
      {submissionSuccessful && (
        <div
          className={clsx(
            "p-4 rounded col-span-full",
            `bg-primary text-primary-contrast`
          )}
        >
          {/* bg-[#27AE60] */}
          <p>
            Bedankt voor je inschrijving. Je ontvangt een email om je
            inschrijving te voltooien.
          </p>
        </div>
      )}

      <form.Subscribe selector={(state) => state.errorMap}>
        {(errorMap) => errorMap.onSubmit && <p>{errorMap.onSubmit}</p>}
      </form.Subscribe>

      <div
        className={clsx(
          "grid grid-cols-subgrid col-span-full gap-y-3 gap-x-3",
          submissionSuccessful && "!hidden"
        )}
      >
        <form.Field
          name="firstname"
          validatorAdapter={zodValidator()}
          validators={{
            onBlur: z.string().min(1, "Dit veld is verplicht"),
          }}
          children={(field) => <Text field={field} label="Voornaam" />}
        />

        <form.Field
          name="lastname"
          validatorAdapter={zodValidator()}
          validators={{
            onBlur: z.string().min(1, "Dit veld is verplicht"),
          }}
          children={(field) => <Text field={field} label="Achternaam" />}
        />

        <form.Field
          name="email"
          validatorAdapter={zodValidator()}
          validators={{
            onBlur: z.string().email("Ongeldig e-mailadres."),
          }}
          children={(field) => (
            <Text field={field} label="E-mailadres" type="email" />
          )}
        />
      </div>

      <form.Field
        name="keyoccupation"
        validatorAdapter={zodValidator()}
        validators={{
          onBlur: z.enum(["true", "false"]),
        }}
        children={(field) => (
          <Radios
            errors={field.state.meta.errors}
            label="Voorrang voor sleutelberoepen"
            help="Sleutelberoepen hebben voorrang bij de toewijzing van woningen met een koopsom tot € 500.000,- Geef hieronder aan of je een sleutelberoep beoefend."
            className={clsx(submissionSuccessful && "!hidden")}
          >
            <Radio field={field} value="true">
              Ja
            </Radio>

            <Radio field={field} value="false">
              Nee
            </Radio>
          </Radios>
        )}
      />

      <form.Field
        name="privacy"
        validatorAdapter={zodValidator()}
        validators={{
          onBlur: z.boolean(),
        }}
        children={(field) => (
          <Checkboxes
            field={field}
            label={"Privacyverklaring"}
            className={clsx(submissionSuccessful && "!hidden")}
          >
            <Checkbox field={field} value="true">
              Ja, ik ga ermee akkoord dat mijn gegevens worden verwerkt door de
              projectontwikkelaar en makelaars.
            </Checkbox>
          </Checkboxes>
        )}
      />

      <form.Field
        name="voorkeur_1"
        validatorAdapter={zodValidator()}
        children={({ name, state: { value } }) => (
          <input type="hidden" name={name} value={value} readOnly={true} />
        )}
      />

      <form.Field
        name="voorkeur_2"
        children={({ name, state: { value } }) => (
          <input type="hidden" name={name} value={value} readOnly={true} />
        )}
      />

      <form.Field
        name="voorkeur_3"
        children={({ name, state: { value } }) => (
          <input type="hidden" name={name} value={value} readOnly={true} />
        )}
      />

      <form.Field
        name="voorkeur_4"
        children={({ name, state: { value } }) => (
          <input type="hidden" name={name} value={value} readOnly={true} />
        )}
      />

      <form.Field
        name="voorkeur_5"
        children={({ name, state: { value } }) => (
          <input type="hidden" name={name} value={value} readOnly={true} />
        )}
      />

      <form.Field
        name="url"
        children={({ name, state: { value } }) => (
          <input type="hidden" name={name} value={value} readOnly={true} />
        )}
      />

      <div
        className={clsx(
          "col-span-full mt-6",
          submissionSuccessful && "!hidden"
        )}
      >
        <form.Subscribe
          selector={(state) => [state.canSubmit, state.isSubmitting]}
          children={([canSubmit, isSubmitting]) => (
            <Button
              variant="primary"
              icon={faPaperPlane}
              className={clsx(
                (!enabled || !canSubmit) &&
                  "pointer-events-none !bg-gray-300 !text-gray-700"
              )}
              disabled={!enabled || !canSubmit}
              aria-disabled={!enabled || !canSubmit}
            >
              Inschrijving verzenden
              {isSubmitting && <span className="ml-2 animate-spin">🔄</span>}
            </Button>
          )}
        />
      </div>
    </form>
  );
}
