import {
  buttonClasses,
  buttonIconClasses,
  isAvailable,
  price,
  slugify,
} from "@/services";
import { DetailedPlot, Phase, Plot } from "@/types";
import {
  faBuilding,
  faCar,
  faChair,
  faCompass,
  faHandWave,
  faLeaf,
  faVectorSquare,
} from "@awesome.me/kit-b9851c3d09/icons/classic/regular";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useParams } from "react-router-dom";
import Link from "../Link";
import useFavorites from "@/hooks/useFavorites";

const getProperties = ({
  room_count,
  living_surface,
  floor,
  sun_position_outdoor,
  parking,
  energy_label,
}: DetailedPlot) => {
  const suffix = parseInt(room_count) === 1 ? "kamer" : "kamers";
  return [
    {
      icon: faVectorSquare,
      text: living_surface && `${living_surface} m²`,
    },
    { icon: faChair, text: room_count && `${room_count} ${suffix}` },
    {
      icon: faBuilding,
      text: !floor
        ? null
        : floor === "0"
        ? "Begane grond"
        : `Woonlaag ${floor}`,
    },
    {
      icon: faCompass,
      text: sun_position_outdoor,
    },
    {
      icon: faCar,
      text: parking,
    },
    {
      icon: faLeaf,
      text: energy_label,
    },
  ];
};

type DetailDataBlockType = {
  plot: Plot;
  statuses: Phase["statuses"];
};

export default function DetailDataBlock({
  plot,
  statuses,
}: Readonly<DetailDataBlockType>) {
  const { projectSlug, phaseSlug } = useParams();

  const status = statuses[plot.status];
  const properties = getProperties(plot).filter(
    ({ text }) => text !== null && text !== undefined && text !== ""
  );
  const available = isAvailable(status);

  const { updateFavoriteIds, favoritedIds } = useFavorites();
  const handleWishlistClick = (plot: Plot) => {
    if (favoritedIds.includes(plot.id)) return;

    updateFavoriteIds(plot.id);
  };

  return (
    <section className="flex flex-col gap-4 p-6 bg-primary text-primary-contrast lg:p-8 lg:shadow-xl">
      <div className="flex items-center space-between">
        <div className="flex flex-col">
          <span className="flex gap-2 text-2xl">
            <span className="font-bold">
              {available
                ? price(parseInt(plot.price), plot.project_type)
                : plot.name}
            </span>
          </span>
          <span className="font-base">
            {available ? status.name : plot.house_type_name}
          </span>
        </div>
      </div>

      <ul className="grid grid-cols-2 gap-px p-px bg-white/25 lg:grid-cols-3">
        {properties.map(({ icon, text }) => (
          <li
            key={slugify(text!)}
            className="flex flex-col items-center p-3 bg-primary shrink-0 grow-0"
          >
            <span className="block h-8 text-lg text-primary-contrast">
              <FontAwesomeIcon icon={icon} />
            </span>
            <span
              className="font-bold leading-5 text-center break-all small text-wrap line-clamp-1"
              title={text ?? undefined}
            >
              {text}
            </span>
          </li>
        ))}

        {properties.length % 2 !== 0 && (
          <li className="w-[calc(100%+2px)] h-[calc(100%+2px)] bg-primary"></li>
        )}
        {properties.length % 3 !== 0 && (
          <li className="w-[calc(100%+2px)] h-[calc(100%+2px)] bg-primary"></li>
        )}
      </ul>
      <div className="w-min">
        <Link
          to={`/projects/${projectSlug}/phases/${phaseSlug}/favorieten`}
          className={buttonClasses("ghost")}
          onClick={() => handleWishlistClick(plot)}
        >
          <FontAwesomeIcon
            icon={faHandWave}
            className={buttonIconClasses("ghost")}
          />
          <span className="whitespace-nowrap">Ik wil deze woning</span>
        </Link>
      </div>
    </section>
  );
}
