import { Hotspot, Media, Plot } from "@/types";
import { LatLng, LatLngExpression } from "leaflet";

const matches = (filteredPlots: { [key: Plot["id"]]: Plot }, plot: Plot) =>
  filteredPlots[plot.id] !== undefined;

const getCoordinates = (
  hotspot: Hotspot,
  background: Media
): LatLngExpression[] | null => {
  try {
    return hotspot.svg.split(",").map((set) => {
      const [x, y] = set.trim().split(" ");
      const leafletX = parseInt(x);
      const leafletY = background.height - parseInt(y); // SVG origin is top-left, Leaflet is bottom-left so we have to invert the Y coordinate
      return new LatLng(leafletY, leafletX);
    });
  } catch (e) {
    console.error(
      `Couldn't convert coordinates to LatLngBounds for hotspot ${hotspot.id}`,
      e
    );
    return null;
  }
};

export { getCoordinates, matches };
