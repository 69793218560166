import { Phase, Plot, Status } from "@/types";
import { isAvailable } from "./Plot";

const matchesHousetype = (
  searchParams: URLSearchParams,
  house_type_name: Plot["house_type_name"]
) => {
  return (
    searchParams.get("house_type_name")?.split(",").includes(house_type_name) ??
    true
  );
};

/**
 * Note: the price filters are optional and don't have to be used together or at the same time
 */
const matchesPrice = (searchParams: URLSearchParams, price: Plot["price"]) => {
  const min = searchParams.get("min_price");
  const max = searchParams.get("max_price");

  // No prices filter set
  if (!min && !max) {
    return true;
    // Only min price set
  } else if (min && !max) {
    return parseInt(price) >= parseInt(min);
    // Only max price set
  } else if (!min && max) {
    return parseInt(price) <= parseInt(max);
    // Both min and max price set
  } else if (min && max) {
    return parseInt(price) >= parseInt(min) && parseInt(price) <= parseInt(max);
  }

  return false;
};

const matchesStatus = (searchParams: URLSearchParams, status: Status) => {
  return (
    searchParams.get("statuses")?.split(",").includes(status.slug) ??
    isAvailable(status)
  );
};

const matchesRoom = (
  searchParams: URLSearchParams,
  room_count: Plot["room_count"]
) => {
  const filterValue = searchParams.get("room_count");
  if (!filterValue) return true;

  return parseInt(room_count) >= parseInt(filterValue);
};

const matchesSunPosition = (
  searchParams: URLSearchParams,
  sun_position_outdoor: Plot["sun_position_outdoor"]
) => {
  return (
    searchParams
      .get("sun_position_outdoor")
      ?.split(",")
      .includes(sun_position_outdoor) ?? true
  );
};

const matchesSurface = (
  searchParams: URLSearchParams,
  living_surface: Plot["living_surface"],
  plot: any
) => {
  const filterValue = searchParams.get("living_surface");
  if (!filterValue) return true;

  return parseInt(living_surface) >= parseInt(filterValue);
};

const matchesFilters = (
  plot: Plot,
  searchParams: URLSearchParams,
  statuses: Phase["statuses"],
  enabledFilters: string[]
) => {
  const {
    house_type_name,
    price,
    status,
    room_count,
    sun_position_outdoor,
    living_surface,
  } = plot;

  return (
    (enabledFilters.includes("house_type_name")
      ? matchesHousetype(searchParams, house_type_name)
      : true) &&
    (enabledFilters.includes("min_price") &&
    enabledFilters.includes("max_price")
      ? matchesPrice(searchParams, price)
      : true) &&
    (enabledFilters.includes("statuses")
      ? matchesStatus(searchParams, statuses[status])
      : true) &&
    (enabledFilters.includes("room_count")
      ? matchesRoom(searchParams, room_count)
      : true) &&
    (enabledFilters.includes("sun_position_outdoor")
      ? matchesSunPosition(searchParams, sun_position_outdoor)
      : true) &&
    (enabledFilters.includes("living_surface")
      ? matchesSurface(searchParams, living_surface, plot)
      : true)
  );
};

/**
 * Generate filter values based on a the given values
 */
const generateRange = (
  values: string[],
  rounding: "up" | "down" | "both" = "down"
): number[] => {
  // Step 0: Remove duplicates and sort the array
  const options = [...new Set(values)]
    .map((num) => parseInt(num))
    .sort((a, b) => a - b);

  // Step 1: Calculate the range of the numbers
  const min = options[0];
  const max = options[options.length - 1];
  const range = max - min;

  // Step 2: Determine the rounding interval based on the range
  let interval: number;
  if (range <= 100) {
    interval = 10;
  } else if (range <= 200) {
    interval = 20;
  } else if (range <= 500) {
    interval = 50;
  } else if (range <= 1000) {
    interval = 100;
  } else if (range <= 10000) {
    interval = 1000;
  } else if (range <= 100000) {
    interval = 10000;
  } else if (range <= 1000000) {
    interval = 100000;
  } else {
    interval = 100;
  }

  const roundUp = (num: number, interval: number) => {
    return Math.ceil(num / interval) * interval;
  };
  const roundDown = (num: number, interval: number) => {
    return Math.floor(num / interval) * interval;
  };

  // Step 3: Round each number to the nearest multiple of the determined interval
  const roundedNumbers = options.map((num, index) => {
    if (rounding === "up") {
      return roundUp(num, interval);
    } else if (rounding === "down") {
      return roundDown(num, interval);
    } else if (rounding === "both" && index === 0) {
      return roundDown(num, interval);
    } else if (rounding === "both" && index === options.length - 1) {
      return roundUp(num, interval);
    } else {
      return roundDown(num, interval);
    }
  });

  // Step 4: Remove duplicates and sort the array
  return [...new Set(roundedNumbers)].sort((a, b) => a - b);
};

export { matchesFilters, generateRange };
