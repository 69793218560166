import { faTimes } from "@awesome.me/kit-b9851c3d09/icons/classic/solid";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import clsx from "clsx";

type FieldProps = {
  field: any;
  label: string;
  help?: string;
  className?: string;
  children?: any;
};

export default function Checkboxes({
  field,
  label,
  help,
  className,
  children,
}: FieldProps) {
  const {
    state: {
      meta: { errors },
    },
  } = field;

  return (
    <fieldset
      className={clsx(
        className,
        "relative col-span-full small flex flex-col gap-y-2"
      )}
    >
      <span className="font-bold text-primary">{label}</span>
      <span className="!leading-6">{help}</span>

      <div className="relative group flex gap-x-4">{children}</div>

      {errors.length ? (
        <span className="flex items-center gap-x-2">
          <span className="text-primary font-[17px]">
            <FontAwesomeIcon icon={faTimes} />
          </span>

          <span className="leading-[17px]">{errors.join(",")}</span>
        </span>
      ) : null}
    </fieldset>
  );
}
