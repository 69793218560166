import { Plot } from "@/types";

const price = (value: number, projectType: Plot["type"] | null) => {
  const format = new Intl.NumberFormat("nl-NL", {
    style: "currency",
    currency: "EUR",
    minimumFractionDigits: 0,
  }).format(value);

  switch (projectType) {
    case "rent":
      return `${format} per maand`;
    case "sale":
      return `${format} v.o.n.`;
    default:
      return format;
  }
};

export { price };
