import { Document as DocumentType } from "@/types";
import {
  faFileImage,
  faFilePdf,
  faFilePowerpoint,
  faFileWord,
} from "@awesome.me/kit-b9851c3d09/icons/classic/regular";
import { IconDefinition } from "@fortawesome/fontawesome-svg-core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import clsx from "clsx";

type DocumentProps = {
  document: DocumentType;
  className?: string;
};

const getFileTypeIcon = (mime_type: string | null): IconDefinition => {
  switch (mime_type) {
    case "application/pdf":
      return faFilePdf;
    case "application/vnd.openxmlformats-officedocument.wordprocessingml.document":
    case "application/msword":
      return faFileWord;
    case "application/vnd.ms-powerpoint":
    case "application/vnd.openxmlformats-officedocument.presentationml.presentation":
      return faFilePowerpoint;
    case "image/apng":
    case "image/avif ":
    case "image/gif":
    case "image/jpeg":
    case "image/png":
    case "image/svg+xml":
    case "image/webp":
      return faFileImage;
    default:
      return faFileImage;
  }
};

export default function Document({
  document,
  className,
}: Readonly<DocumentProps>) {
  const { url, mime_type, name, collection } = document;
  const fileTypeIcon = getFileTypeIcon(mime_type);

  const mapCollectionName = () => {
    switch (collection) {
      case "floorplan":
        return "plattegrond";
      case "documents":
        return "document";
      default:
        return "Other";
    }
  };

  return (
    <a
      target="_blank"
      href={url}
      className={clsx(
        "transition-colors flex items-center rounded-lg p-3 gap-3 border border-black/10 transition-color hocus:bg-secondary/50 active:!bg-secondary/10 lg:p-4",
        className
      )}
      download={true}
      rel="noreferrer"
      title={name}
    >
      {fileTypeIcon && (
        <div className="flex items-center justify-center w-8 h-8 text-base leading-none rounded-full shrink-0 lg:text-xl bg-secondary text-primary lg:w-10 lg:h-10">
          <FontAwesomeIcon icon={fileTypeIcon} className="" />
        </div>
      )}

      <div className="flex flex-col">
        <span className="font-semibold leading-tight small text-primary">
          {mapCollectionName()}
        </span>
        <span className="font-bold leading-tight body text-balance line-clamp-1">
          {name}
        </span>
      </div>
    </a>
  );
}
