import { RefObject, useEffect } from "react";
import { useLocation } from "react-router-dom";

type scrollToTopProps = {
  wrapper: RefObject<HTMLDivElement>;
};

export default function ScrollToTop({ wrapper }: scrollToTopProps) {
  const { pathname } = useLocation();

  useEffect(() => {
    if (!wrapper.current) return;
    const bounds = wrapper.current.getBoundingClientRect();
    window.scrollTo(bounds.x, bounds.y);
  }, [wrapper, pathname]);

  return null;
}
