import clsx from "clsx";

type FieldProps = {
  field: any;
  value: string;
  className?: string;
  children?: any;
};

export default function Radio({
  field,
  value,
  className,
  children,
}: FieldProps) {
  const { name, handleBlur, handleChange } = field;

  return (
    <label
      className={clsx(
        className,
        "flex relative items-start gap-x-2",
        "small select-none gap-x-2 flex group !leading-6"
      )}
    >
      <input
        type="radio"
        name={name}
        value={value}
        onBlur={handleBlur}
        onChange={(e) => handleChange(e.target.value)}
        className="appearance-none block rounded-full h-6 w-6 border border-black/25 transition-colors checked:bg-primary checked:border-primary"
        checked={field.state.value === value}
      />
      {children}
    </label>
  );
}
