import { Count } from "@/components";
import { isAvailable } from "@/services";
import { Phase, Plot, Status } from "@/types";
import { Select } from "antd";
import { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";

type StatusFilterProps = {
  values: Plot["status"][];
  statuses: Phase["statuses"];
};

const mapStatusToOption = (statuses: Status[]) =>
  statuses.map((status) => ({
    label: status.name,
    value: status.slug,
  }));

export default function StatusFilter({
  values,
  statuses,
}: Readonly<StatusFilterProps>) {
  const [searchParams, setSearchParams] = useSearchParams();
  const params = searchParams.get("statuses");

  const availableStatuses = values.map((value) => statuses[value]);

  const defaultSelected = mapStatusToOption(
    availableStatuses.filter((status) => isAvailable(status))
  );

  const userSelected = params
    ?.split(",")
    .filter(
      (value): value is keyof typeof statuses =>
        value !== undefined && value in statuses
    )
    .map((slug) => {
      return {
        label: statuses[slug].name,
        value: slug,
      };
    });

  const [selected, setSelected] = useState<typeof defaultSelected>(
    userSelected || defaultSelected
  );

  useEffect(() => {
    setSelected(userSelected || defaultSelected);

    return () => {
      setSelected(defaultSelected);
    };
  }, [params]);

  const onChange = (value: string[]) => {
    const updated = value.length !== 0 ? value.join(",") : "";
    if (updated === "") {
      searchParams.delete("statuses");
    } else {
      searchParams.set("statuses", updated);
    }

    setSearchParams(searchParams);
  };

  const labelRender = () => {
    const isSelectedSameAsDefault = () => {
      const selectedString = selected
        ?.map((s) => s.value)
        .sort()
        .join("-");
      const defaultString = defaultSelected
        .map((s) => s.value)
        .sort()
        .join("-");

      return selectedString === defaultString;
    };

    return (
      <span className="flex items-center justify-center gap-2 group">
        Beschikbaarheid
        {!isSelectedSameAsDefault() && <Count>{selected.length}</Count>}
      </span>
    );
  };

  return (
    <fieldset className="flex">
      <Select
        className="w-full"
        mode="multiple"
        options={mapStatusToOption(availableStatuses)}
        value={selected}
        defaultValue={defaultSelected}
        labelRender={labelRender}
        onChange={onChange}
        showSearch={false}
        maxTagCount={0}
        maxTagPlaceholder={labelRender()}
        getPopupContainer={(e) => e.closest("main")}
      />
    </fieldset>
  );
}
