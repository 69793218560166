import { Hotspot, Layer, Phase, Plot } from "@/types";
import Card from "./Plot/Card";
import clsx from "clsx";

type ListSectionProps = {
  layer: Layer;
  plots: Plot[];
  filteredPlots: Plot[];
  statuses: Phase["statuses"];
  hotspots: { [key: Hotspot["entity_id"]]: Hotspot };
};
export default function ListSection({
  layer,
  plots,
  filteredPlots,
  statuses,
  hotspots,
}: Readonly<ListSectionProps>) {
  return (
    plots.length > 0 && (
      <li className="relative flex flex-col">
        <h3
          className={clsx(
            "flex font-semibold h2",
            !plots.length && "mb-2",
            plots.length && "mb-8 lg:mb-6"
          )}
        >
          <span>Woonlaag {layer.name}</span>
        </h3>

        {filteredPlots.length ? (
          <ul className="grid grid-cols-1 gap-x-6 gap-y-8 lg:grid-cols-2 lg:gap-x-14 lg:gap-y-16">
            {filteredPlots.map((plot: Plot) => (
              <Card
                key={plot.id}
                plot={plot}
                status={statuses[plot.status]}
                layer={layer}
                hotspot={hotspots[plot.id]}
                as="li"
              />
            ))}
          </ul>
        ) : (
          <h4 className="!font-normal h3">
            Geen woningen beschikbaar die voldoen aan jouw filters.
          </h4>
        )}
      </li>
    )
  );
}
